@import 'include-media/dist/_include-media';
@import 'assets/styles/Colors.scss';
@import 'assets/styles/Constants.scss';

.Header {
    font-family: 'hydrophilia';
}

.MainContent {
    color: white;
    display: flex;
    // overflow-x: auto;
    width: 100vw;
    // padding-bottom: 7rem;

    * {
        font-family: 'bebas-neue-pro';
    }

    .FirstColumn,
    .ThirdColumn {
        width: 20%;
        height: 70vh;
        overflow-y: auto;
    }

    .VisibleMobile{
        display: block !important;
        position: absolute;
        z-index: 3;
        background: black;
        width: 50vw;
        z-index: 200;
    }

    .FirstColumn {
        padding: 1rem 2rem 7rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        @include media('<=mobile') {
            display: none;
        }

        .FirstSection {
            background: rgba(255, 255, 255, 0.1);
            box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            margin-bottom: 3rem;
            padding: 1rem;

            h1 {
                border-bottom: 1px solid white;
                display: inline-block;
                font-size: 1.5rem;
                margin-top: 0;
                min-width: 8rem;
            }

            p {
                margin: 0;
            }
        }

        .BtnSection {
            margin-bottom: 2rem;

            .CenteredButton {
                display: block;
                margin: 0.5rem;
                min-width: 13rem;
                padding-right: 2rem;
                text-align: left;
                text-transform: uppercase;

                
        
                & > span {
                    font-size: 0.9rem;
                    font-weight: bold;
                    margin-left: 0.5rem;
                }
            }
        }

        .ChangeTo {
            align-self: flex-start;
            font-family: 'freight-sans';
            font-weight: bold;
            
            & > p {
                text-align: center;
            }
        }

        .InfoIcon {
            margin: 2rem 0 0.1rem 0.5rem;
            
            img {
                height: 1.5rem;
                width: auto;
            }

            img:hover {
                cursor: pointer;
            }
        }
    }

    .SecondColumn {
        flex-grow: 1;
        min-width: 30.5rem;

        @include media('<=mobile') {
            width: 100%;
            height: 90vh;
        }

        .floatMenu{
            float: right;
            position: absolute;
            right: 10px;
            z-index: 100;
            display: flex;
            flex-direction: column;
            align-items: end;

            @include media('>mobile') {
                display: none;
             }

             .plus{
                width: 30px;
            }
        }

         
    }

    .modalTable{
        background: #2F1034 !important;
    }

    .ThirdColumn {
        align-self: flex-start;
        background: #2F1034;
        border-radius: 10px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        margin: 1rem 1rem 0;
        // min-width: 25rem;
        padding: 0 1rem 1rem;

        @include media('<=mobile') {
            display: none;
         }
        

        & > div {
            display: flex;
            align-items: center;
        }

        .vistabelLogo {
            margin: auto;
            width: 10rem;
        }

        table {
            border-collapse: collapse;
            font-size: 0.85rem;

            tr:not(:last-child) {
                td {
                    border-bottom: 1px solid transparentize(white, 0.5);
                }
            }

            td {
                padding: 0.5rem;
            }

            td:first-child {
                border-right: 1px solid transparentize(white, 0.5);
                font-weight: bold;
            }
        }
            
        .InfoIcon {
            margin: 1rem 0 0.1rem 0.5rem;
            
            img {
                height: 1.2rem;
                width: auto;
            }

            img:hover {
                cursor: pointer;
            }
        }
    }
}

.ReferencesModal {
    max-width: 60%;

    * {
        font-family: 'bebas-neue-pro';
    }

    & > div {
        background: transparentize(black, 0.2);
        border-radius: 1.875rem;
        color: white;
        display: inline-block;
        font-size: 1.2rem;
        padding: 2rem;

        p {
            display: inline-block;
            margin-top: 1rem;
        }
    }
}