@import 'include-media/dist/_include-media';
@import 'assets/styles/Colors.scss';
@import 'assets/styles/Constants.scss';

.Wrapper {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;

    @include media('<=mobile') {
        position: relative;
    }

    .Footer {
        color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 3rem;
        padding: 0 4rem;

        @include media('<=mobile') {
            display: block;
            height: auto;
            text-align: center;

            a{
                padding: 20px 0px 20px 0px;
                width: 30%;
                margin: auto;
            }
        }
    
        &.VioletBg {
            background: #53284F;
        }
    
        &.GreyBg {
            background: #343434;
        }

        &.OrangeBg {
            background: #FF5D1F;
            padding: 20px;
            height: 40px;
        }

        &.OrangeBg .disclaimer {
            text-align: center;
            font-size: 14px;
            font-weight: 400;
        }

        &.BlueBg {
            background: #0C2355;
        }
        
        .PrivacyAndTermsMobile{
            display: none;
            padding: 20px 0px 20px 0px;
            @include media('<=mobile') {
                display: block;
            }

            .Terms {
                color: white;
                text-decoration: underline;
        
                &:hover {
                    cursor: pointer;
                }
            }
        }
        .PrivacyAndTerms {
            display: flex;

            @include media('<=mobile') {
                display: none;
            }
            
            .Terms {
                color: white;
                text-decoration: underline;
        
                &:hover {
                    cursor: pointer;
                }
            }
        }
    
        .UserGuideAndTerms {
            color: white;
            display: flex;
            text-decoration: underline;
    
            & > img {
                margin-left: 0.5rem;
            }
    
            &:hover {
                cursor: pointer;
            }
        }
    
    }
    
    .SecondFooter {
        background: #1C1C1C;
        color: white;
        display: flex;
        font-family: inherit;
        justify-content: space-between;
        align-items: center;
        padding: 0 8rem 0 3rem;
        min-height: 4rem;
        max-height: 4rem;

        @include media('<=mobile') {
                display: block;
                height: auto;
                text-align: center;
                max-height: none;
                padding: 0 0 0 0;
        }
    
        .Logo {
            max-width: 8rem;
            max-height: inherit;
            position: relative; 
            padding: 20px 0px 20px 0px;
        }

        .disclaimerlogo {
            z-index: 100;
            font-size: 10px; 
            padding: 100px; 

            @include media('<=mobile') {
                padding: 0px;
            }
            
        }

        .mobileBlock{
            @include media('<=mobile') {
                padding: 20px 0px 20px 0px;
            }
        }
    }

    .SecondFooterHarmonyca {
        background: #0C2355;
        color: white;
        display: flex;
        font-family: inherit;
        justify-content: space-between;
        align-items: center;
        padding: 10px 3rem 10px 3rem;
        min-height: 35px;
        max-height: 35px;
    
        .Logo {
            max-width: 8rem;
            max-height: inherit;
            position: absolute; 
        }

        .disclaimerlogo {
            z-index: 100;
            font-size: 10px; 
            padding: 100px; 
            
        }
    }

}