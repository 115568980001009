@import 'include-media/dist/_include-media';
@import 'assets/styles/Colors.scss';
@import 'assets/styles/Constants.scss';


.Header {
    
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    padding-top: 5px;
    padding-bottom: 5px;
    /* identical to box height */
    
    text-align: center;
    
    color: #081C48;

    p{
        font-family: 'Founders Grotesk';
    }
}

.Footer{
    background: #0C2355;
}

.MainContent {
    color: #0C2355;
    font-family: 'Founders Grotesk';
    font-weight: 700;
    font-size: 16px;
    display: flex;
    // overflow-x: auto;
    width: 100%;
    text-align: center;
    // padding-bottom: 7rem;

    background: linear-gradient(90deg, #9ABFE4 16.22%, #B3ABCA 56.23%, #F1D1D4 97.38%) !important;

    // * {
    //     //font-family: 'bebas-neue-pro';
    // }

    .FirstColumn,
    .ThirdColumn {
        padding-top: 10vh !important;
        width: 17%;
       // height: 70vh;
        overflow-y: auto;
    }

    .VisibleMobile{
        display: block !important;
        position: absolute;
        z-index: 3;
        background: linear-gradient(90deg, #9ABFE4 16.22%, #B3ABCA 56.23%, #F1D1D4 97.38%) !important;
        width: 50vw;
        z-index: 200;
    }

    .FirstColumn {
        padding: 1rem 2rem 10rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        @include media('<=mobile') {
            display: none;
        }

        .InfoIcon{
            text-align: left;
        }

        .FirstSection {
            // background: linear-gradient(197.96deg, rgba(255, 255, 255, 0.3) -1.81%, rgba(255, 255, 255, 0.1) 78%);
            // box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25), inset 2px 2px 3px -2px #FFFFFF;
            // backdrop-filter: blur(25px);
            // border-radius: 12px;
            background: #EC6837;
            border-radius: 10px;
            margin-bottom: 3rem;
            padding: 1rem;
            text-align: center;

            .shield{
                width: 47px;
                margin: auto;
            }

            .points{
                width: 54px;
                margin: auto;
                margin-bottom: 20px;
            }

            .shieldHeader{
                // font-family: 'Founders Grotesk';
                // font-style: normal;
                // font-weight: 700;
                // font-size: 20px;
                // line-height: 24px;
                // text-align: center;
                // color: #0C2355;
                font-family: 'Saol Display';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 26px;
                text-align: center;
                color: #FFFFFF;

            }
            
            .text{
                font-family: 'Founders Grotesk';
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
                color: #FFFFFF;
                text-align: left;
            }

            // h1 {
            //     border-bottom: 1px solid white;
            //     display: inline-block;
            //     font-size: 1.5rem;
            //     margin-top: 0;
            //     min-width: 8rem;
            // }

            // p {
            //     margin: 0;
            // }
        }

        .BtnSection {
            margin-bottom: 2rem;

            .CenteredButton {
                display: block;
                margin: 0.5rem;
                min-width: 13rem;
                padding-right: 2rem;
                text-align: left;
                text-transform: uppercase;

                background: linear-gradient(180deg, #1845A6 0%, #0C2355 61.98%);
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                border-radius: 100px;
                transform: rotate(0.15deg);

                
        
                & > span {
                    font-size: 0.9rem;
                    font-weight: bold;
                    margin-left: 0.5rem;
                }
            }
        }

        .InfoIcon {
            margin: 2rem 0 0.1rem 0.5rem;
            
            img {
                height: 1.5rem;
                width: auto;
            }

            img:hover {
                cursor: pointer;
            }
        }
    }

    .SecondColumn {
        flex-grow: 1;
        min-width: 30.5rem;

        @include media('<=mobile') {
            width: 100%;
            height: 85vh;
        }

        .floatMenu{
            float: right;
            position: absolute;
            right: 10px;
            z-index: 100;
            display: flex;
            flex-direction: column;
            align-items: end;

            @include media('>mobile') {
                display: none;
             }

             .plus{
                width: 30px;
            }
        }
    }

    .ThirdColumn {
        text-align: center;
        //align-self: flex-start;
        //background: #2F1034;
        // border-radius: 10px;
        // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        // margin: 1rem 1rem 0;
        // min-width: 25rem;
        padding: 0 1rem 1rem;

        @include media('<=mobile') {
            display: none;
         }

        .ChangeTo {            
            text-align: center;
            margin: 2rem auto 0;
            margin-bottom: 20px;
            
            & > .ChangeToText {
                font-family: "freight-sans" !important;
                text-align: center;
                font-weight: bold;
                color: #0C2355 !important;
            }

            & > div {
                margin: auto;
                background: none !important;

                width: 15rem;
            }
        
        }
        

        & > div {
            // display: flex;
            align-items: center;
        }

        .vistabelLogo {
            margin: auto;
            width: 10rem;
        }

        table {
            border-collapse: collapse;
            font-size: 0.85rem;

            tr:not(:last-child) {
                td {
                    border-bottom: 1px solid transparentize(white, 0.5);
                }
            }

            td {
                padding: 0.5rem;
            }

            td:first-child {
                border-right: 1px solid transparentize(white, 0.5);
                font-weight: bold;
            }
        }
            
        .InfoIcon {
            margin: 1rem 0 0.1rem 0.5rem;
            
            img {
                height: 1.2rem;
                width: auto;
            }

            img:hover {
                cursor: pointer;
            }
        }
    }
}

.ReferencesModal {
    max-width: 60%;

    * {
        font-family: 'bebas-neue-pro';
    }

    & > div {
        background: transparentize(black, 0.2);
        border-radius: 1.875rem;
        color: white;
        display: inline-block;
        font-size: 1.2rem;
        padding: 2rem;

        p {
            display: inline-block;
            margin-top: 1rem;
        }
    }
}