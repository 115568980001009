// por pereza va aqui :(
    .ThirdColumn {
        align-self: flex-start;
        background: #2F1034;
        border-radius: 10px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        margin: 1rem 1rem 0;
        // min-width: 25rem;
        padding: 0 1rem 1rem;
        color: white;

        & > div {
            display: flex;
            align-items: center;
        }

        .vistabelLogo {
            margin: auto;
            width: 10rem;
        }

        table {
            border-collapse: collapse;
            font-size: 0.85rem;

            tr:not(:last-child) {
                td {
                    border-bottom: 1px solid transparentize(white, 0.5);
                }
            }

            td {
                padding: 0.5rem;
            }

            td:first-child {
                border-right: 1px solid transparentize(white, 0.5);
                font-weight: bold;
            }
        }
        
        .InfoIcon {
            margin: 1rem 0 0.1rem 0.5rem;
            
            img {
                height: 1.2rem;
                width: auto;
            }

            img:hover {
                cursor: pointer;
            }
        }
    }