@import 'include-media/dist/_include-media';
@import 'assets/styles/Colors.scss';
@import 'assets/styles/Constants.scss';
.Header {
    
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    padding-top: 5px;
    padding-bottom: 5px;
    /* identical to box height */
    
    text-align: center;
    
    color: #081C48;

    p{
        font-family: 'Founders Grotesk';
    }
}

.Footer{
    background: #0C2355;
}

.MainContent {
    color: #0C2355;
    font-family: 'Founders Grotesk';
    font-weight: 700;
    font-size: 16px;
    display: flex;
    // overflow-x: auto;
    width: 100%;
    text-align: center;
    // padding-bottom: 7rem;
    padding-bottom: 13rem;

    background: url(/assets/images/harmonycainfobg.png), linear-gradient(180deg, rgba(12, 35, 85, 0.87) 0%, #081C49 76.83%);
    background-size: cover;
    // * {
    //     //font-family: 'bebas-neue-pro';
    // }

    @include media('<=mobile') {
        height: auto;
        padding-bottom: 1rem;
    }

    .DetailsWrapper{
        // width: 100%;
        // height: 100px;

        margin: auto;

        .InfoIcon{
            text-align: left;
            width: 22px;
            float: left;
        }

        h2{
            font-family: 'Founders Grotesk';
            font-style: normal;
            font-weight: 700;
            font-size: 36px;
            line-height: 43px;
            text-align: center;
            color: white;

            img{
                width: 116px;
            }
        }

        .grid{
            display: grid;
            gap: 2rem;
            grid-template-columns: repeat(3,minmax(0,1fr));
            max-width: 72rem;
            margin: 4rem auto;

            @include media('<=mobile') {
                display: flex;
                flex-direction: column;
            }

            .ChildGrid2{
                background: #3A85C4;
                box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
                border-radius: 10px;
            }

            .ChildGrid {
                // background: linear-gradient(197.96deg, rgba(255, 255, 255, 0.3) -1.81%, rgba(255, 255, 255, 0.1) 78%);
                // box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25), inset 2px 2px 3px -2px #FFFFFF;
                // backdrop-filter: blur(25px);
                // border-radius: 12px;
                background: #EC6836;
                border-radius: 10px;
            }

            .ChildGrid, .ChildGrid2 {
                display: flex;
                flex-direction: column;
                text-align: center;
                padding: 2rem;
                color: white;
                align-items: center;

                .logoGrid{
                    width: 77px;
                }

                .logoSub{
                    width: 54px;
                    margin-bottom: 20px
                }

                h3{
                    // font-family: 'Founders Grotesk';
                    // font-style: normal;
                    // font-weight: 700;
                    // font-size: 20px;
                    // line-height: 24px;
                    // text-align: center;
                    font-family: 'Saol Display';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 31px;
                    text-align: center;
                    color: #FFFFFF;
                }

                p{
                    font-family: 'Founders Grotesk';
                    font-style: normal;
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 19px;
                    text-align: center;
                    color: #FFFFFF;
                }

                p.italic{
                    font-family: 'Founders Grotesk';
                    font-style: italic;
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 17px;
                    text-align: center;
                    color: #FFFFFF;
                }
            }
        }
    }

   

    

    .FirstColumn,
    .ThirdColumn {
        padding-top: 10vh !important;
        width: 17%;
       // height: 70vh;
        overflow-y: auto;
    }

    .FirstColumn {
        padding: 1rem 2rem 7rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .FirstSection {
            background: linear-gradient(197.96deg, rgba(255, 255, 255, 0.3) -1.81%, rgba(255, 255, 255, 0.1) 78%);
            box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25), inset 2px 2px 3px -2px #FFFFFF;
            backdrop-filter: blur(25px);
            border-radius: 12px;
            margin-bottom: 3rem;
            padding: 1rem;
            text-align: center;

            .shield{
                width: 47px;
                margin: auto;
            }

            .points{
                width: 54px;
                margin: auto;
                margin-bottom: 20px;
            }

            .shieldHeader{
                font-family: 'Founders Grotesk';
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 24px;
                text-align: center;
                color: #0C2355;
            }
            
            .text{
                font-family: 'Founders Grotesk';
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
                color: #0C2355;
                text-align: left;
            }

            // h1 {
            //     border-bottom: 1px solid white;
            //     display: inline-block;
            //     font-size: 1.5rem;
            //     margin-top: 0;
            //     min-width: 8rem;
            // }

            // p {
            //     margin: 0;
            // }
        }

        .BtnSection {
            margin-bottom: 2rem;

            .CenteredButton {
                display: block;
                margin: 0.5rem;
                min-width: 13rem;
                padding-right: 2rem;
                text-align: left;
                text-transform: uppercase;

                background: linear-gradient(180deg, #1845A6 0%, #0C2355 61.98%);
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                border-radius: 100px;
                transform: rotate(0.15deg);

                
        
                & > span {
                    font-size: 0.9rem;
                    font-weight: bold;
                    margin-left: 0.5rem;
                }
            }
        }

        .InfoIcon {
            margin: 2rem 0 0.1rem 0.5rem;
            
            img {
                height: 1.5rem;
                width: auto;
            }

            img:hover {
                cursor: pointer;
            }
        }
    }

    .SecondColumn {
        flex-grow: 1;
        min-width: 30.5rem;
    }

    .ThirdColumn {
        text-align: center;
        //align-self: flex-start;
        //background: #2F1034;
        // border-radius: 10px;
        // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        // margin: 1rem 1rem 0;
        // min-width: 25rem;
        padding: 0 1rem 1rem;

        .ChangeTo {            
            text-align: center;
            margin: 2rem auto 0;
            margin-bottom: 20px;
            
            & > .ChangeToText {
                font-family: "freight-sans" !important;
                text-align: center;
                font-weight: bold;
                color: #0C2355 !important;
            }

            & > div {
                margin: auto;
                background: none !important;

                width: 15rem;
            }
        
        }
        

        & > div {
            // display: flex;
            align-items: center;
        }

        .vistabelLogo {
            margin: auto;
            width: 10rem;
        }

        table {
            border-collapse: collapse;
            font-size: 0.85rem;

            tr:not(:last-child) {
                td {
                    border-bottom: 1px solid transparentize(white, 0.5);
                }
            }

            td {
                padding: 0.5rem;
            }

            td:first-child {
                border-right: 1px solid transparentize(white, 0.5);
                font-weight: bold;
            }
        }
            
        .InfoIcon {
            margin: 1rem 0 0.1rem 0.5rem;
            
            img {
                height: 1rem;
                width: auto;
            }

            img:hover {
                cursor: pointer;
            }
        }
    }
}

.ReferencesModal {
    max-width: 60%;

    * {
        font-family: 'bebas-neue-pro';
    }

    & > div {
        background: transparentize(black, 0.2);
        border-radius: 1.875rem;
        color: white;
        display: inline-block;
        font-size: 1.2rem;
        padding: 2rem;

        p {
            display: inline-block;
            margin-top: 1rem;
        }
    }
}